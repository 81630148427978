import React, { useState, useEffect } from "react";
import ArticleModal from "./ArticleModal";
import classes from "./Articles.module.css";
// utils
import { loadPdfs, deletePdf } from "../../../../utils/TravauxUtils";
// Mui
import CircularProgress from "@material-ui/core/CircularProgress";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const Articles = ({ claims, pdfs, setPdfs, width }) => {
  const [show, setShow] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [loading, setLoading] = useState(true);

  const [selectedPdf, setSelectedPdf] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState(0);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    setLoading(true);
    loadPdfs(setPdfs, setLoading);
  }, []);

  const handleOpen = (index) => {
    setSelectedArticle(index);
    setShow(true);
  };
  const handleOpenPdf = (index) => {
    setSelectedPdf(index);
    setShowPdf(true);
  };

  return (
    <div className={classes.Articles}>
      {pdfs && pdfs[0] ? (
        <>
          <ArticleModal
            show={showPdf}
            setShow={setShowPdf}
            type={"pdf"}
            content={pdfs[selectedPdf].url}
            screenWidth={width}
          />
          {pdfs.map((article, index) => {
            return (
              <div key={article.id} style={{ position: "relative" }}>
                <div
                  onClick={() => handleOpenPdf(index)}
                  className={classes.ArticleCard}
                >
                  <span className={classes.Emoji} role="img" aria-label="emoji">
                    📰
                  </span>
                  <p>{article.title}</p>
                </div>
                {claims && claims.admin && (
                  <div
                    className={classes.DeleteButton}
                    onClick={() => {
                      deletePdf(article.id);
                      loadPdfs(setPdfs);
                    }}
                  >
                    <Tooltip title="Supprimer l'article">
                      <IconButton aria-label="delete">
                        <HighlightOffRoundedIcon
                          color="primary"
                          style={{ fontSize: "2rem" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : loading ? (
        <div
          style={{
            margin: "1rem auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}

      {/* <ArticleModal
        show={show}
        setShow={setShow}
        type={articles[selectedArticle].type}
        content={articles[selectedArticle].content}
      /> */}
      {articles ? (
        articles.map((article, index) => {
          return (
            <div
              className={classes.ArticleCard}
              key={article.id}
              onClick={() => handleOpen(index)}
            >
              <span className={classes.Emoji} role="img" aria-label="emoji">
                {article.emoji}
              </span>
              <p>{article.title}</p>
            </div>
          );
        })
      ) : (
        <div
          style={{
            margin: "1rem auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Articles;
