import React from "react";

export const CalendarData = [
  {
    id: 0,
    emoji: "🤝",
    title: "Etape 1",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "🤝",
        title: "",
        date: "",
        duration: "",
        description: "Réunion publique par groupe de 20 personnes",
      },
      {
        id: 1,
        emoji: "🗳️",
        title: "",
        date: "",
        duration: "",
        description:
          "Lancement Concertation locative et de la Co-construction avec les locataires avec l’appui de la SHLMR et de l’association Cœur Social (26/10– 26/12/2021)",
      },
      {
        id: 2,
        emoji: "📈",
        title: "",
        date: "",
        duration: "",
        description:
          "Présentation du projet de réhabilitation et résidentialisation, ses acteurs, le planning et les incidences sur le loyer et les charges",
      },
      {
        id: 3,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description:
          "Présentation de l’outil de co-construction COREHAB et visite virtuelle du logement",
      },
      {
        id: 4,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Accompagnement des locataires à l’utilisation de l’outil",
      },
      {
        id: 5,
        emoji: "📝",
        title: "",
        date: "",
        duration: "",
        description:
          "Distribution de l’accord locatif avec la nature complète des travaux",
      },
      {
        id: 6,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description:
          "Au bout d’un mois : Atelier sur la Maîtrise de l'énergie par la SPL HORIZONS - Présentation des gestes à adopter au quotidien pour réduire sa consommation en eau et en électricité",
      },
      {
        id: 7,
        emoji: "🏘️",
        title: "",
        date: "",
        duration: "",
        description:
          "Programmation de visite avec la SPL et les locataires au sein de leur logement, afin de faire un bilan énergétique de leur logement. Distribution de kits éco responsables (mousseurs, pommeau de douche, ampoule LED) pour les locataires ",
      },
      {
        id: 8,
        emoji: "ℹ️",
        title: "",
        date: "",
        duration: "",
        description:
          "Durant la durée de la concertation, l’association ainsi que la MOUS vous accompagneront dans la réalisation des choix sur COREHAB, vous apporteront de l’information complémentaire sur le projet, et seront à votre écoute",
      },
      {
        id: 9,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Accès à l’application durant 6 semaines",
      },
      {
        id: 10,
        emoji: "🤝",
        title: "",
        date: "",
        duration: "",
        description: "Retour de l’accord locatif sous 8 semaines",
      },
    ],
  },
  {
    id: 1,
    emoji: "📜",
    title: "Etape 2",
    date: "",
    duration: "",
    plan: "Carte_Phase-3.svg",
    substeps: [
      {
        id: 0,
        emoji: "📜",
        title: "",
        date: "",
        duration: "",
        description:
          "Présentation de la synthèse de résultats de la co-construction via COREHAB",
      },
      {
        id: 1,
        emoji: "📈",
        title: "",
        date: "",
        duration: "",
        description:
          "Restitution des données récoltées lors des 6 semaines de co-construction",
      },
      {
        id: 2,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Permanence avec la MOUS possible jusqu'à fin décembre",
      },
      {
        id: 3,
        emoji: "🖥️",
        title: "",
        date: "",
        duration: "",
        description: "Visite virtuelle du logement témoin possible",
      },
      {
        id: 4,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "Synthèse de la co-construction sera publiée sur COREHAB",
      },
      {
        id: 5,
        emoji: "📮",
        title: "",
        date: "",
        duration: "",
        description:
          "Notification par voie postale des résultats de la concertation locative et de la synthèse des résultats de COREHAB ",
      },
    ],
  },
  {
    id: 2,
    emoji: "🏘️",
    title: "Etape 3",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "🏘️",
        title: "",
        date: "",
        duration: "",
        description:
          "Présentation du logement témoin physique comprenant les choix réalisés  à l’unanimité par les familles (2ème trimestre 2022)",
      },
    ],
  },
  {
    id: 3,
    emoji: "🔍",
    title: "Etape 4",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "🔍",
        title: "",
        date: "",
        duration: "",
        description:
          "Consultation des entreprises qui vont réaliser les travaux ( 2ème trimestre 2022)",
      },
      {
        id: 1,
        emoji: "📝",
        title: "",
        date: "",
        duration: "",
        description:
          "Production du dossier de consultation des entreprises par la maîtrise d’œuvre ( 2 mois)",
      },
      {
        id: 2,
        emoji: "🤝",
        title: "",
        date: "",
        duration: "",
        description:
          "Consultation des entreprises et attributions des marchés (période de 3 mois)",
      },
    ],
  },
  {
    id: 4,
    emoji: "🏗️",
    title: "Etape 5",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "👷",
        title: "",
        date: "",
        duration: "",
        description: "La réalisation des travaux (durée de 36 mois)",
      },
      {
        id: 1,
        emoji: "🏁",
        title: "",
        date: "",
        duration: "",
        description:
          "Ordre de service de démarrage des travaux : 4ème trimestre 2022",
      },
      {
        id: 2,
        emoji: "🏁",
        title: "",
        date: "",
        duration: "",
        description: "FIN DES TRAVAUX :  4ème trimestre 2025 (hors aléas)",
      },
      {
        id: 3,
        emoji: "🤝",
        title: "",
        date: "",
        duration: "",
        description: "Année de garantie de parfait achèvement (en 2026)",
      },
    ],
  },
];
