import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Title from "../elements/CardTitle";
import NextButton from "../elements/NextButton";
import Wrapper from "../elements/CardWrapper";
import Rating from "@material-ui/lab/Rating";
import LinearProgress from "@material-ui/core/LinearProgress";
import { uploadAnswer } from "../../utils/TravauxUtils";

const Image = styled.img`
  width: 35rem;
  @media screen and (min-width: 910px) {
    width: 100%;
  }
`;
const ImageWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
  position: relative;
  margin-bottom: -3rem;
  margin-top: -3rem;
  @media screen and (min-width: 910px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const RatingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;

  padding: 1rem 1.5rem;
  margin: 1.5rem;
  background: #f9f9f9;
  box-shadow: 0px 7px 0px rgba(0, 0, 0, 0.25), 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
`;
const Question = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const RatingType = ({
  dispatch,
  contentData,
  cardID,
  cardIndex,
  contentIndex,
  contentLength,
  currentUser,
  cardsLength,
}) => {
  const { contentId, question, title, imageUrl, value, options, comment } =
    contentData;

  const handleChange = (
    newValue,
    contentID,
    cardID,
    contentIndex,
    cardIndex
  ) => {
    dispatch({
      type: "UPDATE_RATING",
      cardID,
      contentID,
      contentIndex,
      cardIndex,
      newValue: newValue,
    });
  };

  const handleSubmit = (cardID, contentId, value) => {
    let answers = {
      cardID: cardID,
      ["value"]: value,
      ["comment"]: comment,
    };
    uploadAnswer(currentUser.uid, cardID, contentId, answers, dispatch);
  };

  return (
    <Wrapper
      variant="cardMain"
      layoutId="wrapper"
      initial={{ borderRadius: 40 }}
      animate={{ borderRadius: 0 }}
      transition={{ duration: 0.5 }}
    >
      <LinearProgress
        variant="determinate"
        style={{ width: "85%", margin: "1rem auto" }}
        color="secondary"
        value={(contentIndex / contentLength) * 100}
      />
      <Title
        layoutId="title"
        transition={{ duration: 0.5 }}
        color="#fff"
        style={{ paddingLeft: "2rem" }}
      >
        {title}
      </Title>

      <ImageWrapper
        layoutId="imageIllustration"
        drag="x"
        dragConstraints={{ left: -50, right: 50 }}
      >
        <Image
          alt="La résidentialisation"
          src={require(`../../assets/travaux/${imageUrl}`)}
        />
      </ImageWrapper>

      <RatingWrapper>
        <Question>{question}</Question>
        <Rating
          name="simple-controlled"
          value={value}
          size="large"
          onChange={(event, newValue) => {
            handleChange(newValue, contentId, cardID, contentIndex, cardIndex);
          }}
        />
      </RatingWrapper>

      <ButtonWrapper>
        <NextButton
          handleSubmit={() => handleSubmit(cardID, contentId, value)}
          href={
            parseInt(contentIndex) < contentLength - 1
              ? `/travaux/${cardID}/${parseInt(contentIndex) + 1}`
              : "/travaux"
          }
        >
          Valider
        </NextButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default RatingType;
