import React from "react";

export const travauxCardsData = [
  {
    id: "i1",
    type: "newLayout",
    titleStrong: "La résidentialisation",
    titleWeak: "",
    description:
      "La résidentialisation est une étape qui consiste à remanier l’ensemble de votre résidence: revoir les accès et les cheminements et proposer de nouveaux espaces dans le jardin, sont autant de tâches que vont mener les équipes chargées de la réhabilitation. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i1-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "La résidentialisation est une étape qui consiste à remanier l’ensemble de votre résidence: revoir les accès et les cheminements et proposer de nouveaux espaces dans le jardin, sont autant de tâches que vont mener les équipes chargées de la réhabilitation. Pour en savoir plus, cliquez sur explorer.",
        title: "La résidentialisation",
        video: {
          url: "https://youtu.be/VDa2e_Sdums",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i2",
    type: "newLayout",
    titleStrong: "Le traitement",
    titleWeak: "des déchets",
    description:
      "Cette opération consiste à revoir la gestion des déchets et encombrants au sein de votre résidence. Les conteneurs présents aujourd’hui pour collecter les déchets sont peu pratiques à l’usage, peu esthétiques et pas des plus respectueux de l’environnement. Ils seront donc remplacés! Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i2-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Cette opération consiste à revoir la gestion des déchets et encombrants au sein de votre résidence. Les conteneurs présents aujourd’hui pour collecter les déchets sont peu pratiques à l’usage, peu esthétiques et pas des plus respectueux de l’environnement. Ils seront donc remplacés! Pour en savoir plus, cliquez sur explorer.",
        title: "Le traitement des déchets",
        video: {
          url: "https://youtu.be/Oh9C58wSoxM",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i3",
    type: "newLayout",
    titleStrong: "Les travaux de voiries",
    titleWeak: "parking et cheminements",
    description:
      "Ces travaux vous permettront de mieux vous déplacer au sein de votre résidence. Que vous soyez à pied, en voiture, avec une poussette, à vélo ou en chaise roulante, l’important est d’arriver sain et sauf à votre logement! Pour en savoir plus sur les travaux, cliquez sur explorer.",
    thumbnail: "i3-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux vous permettront de mieux vous déplacer au sein de votre résidence. Que vous soyez à pied, en voiture, avec une poussette, à vélo ou en chaise roulante, l’important est d’arriver sain et sauf à votre logement! Pour en savoir plus sur les travaux, cliquez sur explorer.",
        title: "Les travaux de voiries parking et cheminements",
        video: {
          url: "https://youtu.be/MysEkU7Gqr4",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i4",
    type: "newLayout",
    titleStrong: "Les espaces",
    titleWeak: "verts",
    description:
      "Tous les espaces verts de l’opération seront réaménagés et compteront à terme 151 arbres dont 57 arbres existants conservés. L’aménagement des espaces verts offre un cadre paysager de qualité en pied de bâtiment et dans les espaces communs et permet l’intégration harmonieuse des stationnements. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i4-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Tous les espaces verts de l’opération seront réaménagés et compteront à terme 151 arbres dont 57 arbres existants conservés. L’aménagement des espaces verts offre un cadre paysager de qualité en pied de bâtiment et dans les espaces communs et permet l’intégration harmonieuse des stationnements. Pour en savoir plus, cliquez sur explorer.",
        title: "Les espaces verts",
        video: {
          url: "https://youtu.be/1ZGBOVe7hRo",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i5",
    type: "newLayout",
    titleStrong: "Les travaux pour améliorer",
    titleWeak: "la température à l’intérieur du logement",
    description:
      "Pour améliorer la température à l’intérieur de votre logement, les architectes prévoient d’agir sur les façades de votre immeuble et sur les toitures. Votre logement bénéficiera également d’aménagements dans ce sens. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i5-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Pour améliorer la température à l’intérieur de votre logement, les architectes prévoient d’agir sur les façades de votre immeuble et sur les toitures. Votre logement bénéficiera également d’aménagements dans ce sens. Pour en savoir plus, cliquez sur explorer.",
        title:
          "Les travaux pour améliorer la température à l’intérieur du logement",
        video: {
          url: "https://youtu.be/uNCoV8puH5w",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i6",
    type: "newLayout",
    titleStrong: "Les travaux sur les parties",
    titleWeak: "communes de votre immeuble",
    description:
      "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
    thumbnail: "i6-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Ces travaux consistent à remettre à neuf et moderniser le hall et les parties communes de votre immeuble: De la porte d’entrée à la cage d’escalier. L’objectif est de rendre votre cadre de vie plus agréable et plus pratique qu’il ne l’est aujourd’hui. Pour en savoir plus à ce sujet, cliquez sur explorer.",
        title: "Les travaux sur les parties communes de votre immeuble",
        video: {
          url: "https://youtu.be/snDssTmmNQo",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i7",
    type: "newLayout",
    titleStrong: "Les logements",
    titleWeak: "",
    description:
      "Votre logement sera complètement rénové! Aux termes des travaux dans votre logement, vos portes intérieures seront rénovées ou en partie remplacées, la peinture refaite...et des améliorations seront apportées pour un meilleur confort thermique. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i7-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Votre logement sera complètement rénové! Aux termes des travaux dans votre logement, vos portes intérieures seront rénovées ou en partie remplacées, la peinture refaite...et des améliorations seront apportées pour un meilleur confort thermique. Pour en savoir plus, cliquez sur explorer.",
        title: "Les logements",
        video: {
          url: "https://youtu.be/jQF1XL_woog",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i8",
    type: "newLayout",
    titleStrong: "Les réseaux",
    titleWeak: "d’eau et d’électricité",
    description:
      "Les travaux prévoient de revoir tout ce qui distribue ou évacue l’eau de votre immeuble mais aussi tous les réseaux de distribution de l’électricité. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i8-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Les travaux prévoient de revoir tout ce qui distribue ou évacue l’eau de votre immeuble mais aussi tous les réseaux de distribution de l’électricité. Pour en savoir plus, cliquez sur explorer.",
        title: "Les réseaux d’eau et d’électricité",
        video: {
          url: "https://youtu.be/qqXbWj_Xgo8",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "i9-cd",
    type: "newLayout",
    titleStrong: "Création",
    titleWeak: "de balcons",
    description:
      "Des travaux de fonds seront prévus sur les immeubles de toute la résidence. Pour votre immeuble, les travaux consisteront en la création de balcons pour chaque logement. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i9-cd-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Des travaux de fonds seront prévus sur les immeubles de toute la résidence. Pour votre immeuble, les travaux consisteront en la création de balcons pour chaque logement. Pour en savoir plus, cliquez sur explorer.",
        title: "Création de balcons",
        video: {
          url: "https://youtu.be/g9mIVwYtn8I",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["c", "d"],
  },
  {
    id: "i9-fg",
    type: "newLayout",
    titleStrong: "Amélioration",
    titleWeak: "de l’accessibilité",
    description:
      "Pour votre immeuble, les travaux consisteront en l’amélioration de l’accessibilité avec la création de deux ascenseurs. Pour en savoir plus, cliquez sur explorer.",
    thumbnail: "i9-fg-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Pour votre immeuble, les travaux consisteront en l’amélioration de l’accessibilité avec la création de deux ascenseurs. Pour en savoir plus, cliquez sur explorer.",
        title: "Amélioration de l’accessibilité",
        video: {
          url: "https://youtu.be/eYgBepVB69E",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: ["f", "g"],
  },
  {
    id: "c1",
    type: "newLayout",
    titleStrong: "Création d’aires",
    titleWeak: "de détente et de partage",
    description:
      "Le projet de résidentialisation prévoit l’implantation de deux de détente et de partage : L’une pour l’îlot des bâtiments ABCD (plutôt à destination des plus jeunes) à l’angle des bâtiments A et B. L’autre (plutôt à destination des séniors) pour l’îlot des bâtiments EFGH au sud du bâtiment G. Aidez-nous à mieux cerner vos attentes en personnalisant l’aire de détente de partage idéale. Pour cela, cliquez sur personnaliser*. (*L’aménagement de ces espaces se fera en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C1-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Création d’aires de détente et de partage",
        question:
          "Quel type de jeux ou espace de détente souhaitez-vous voir dans l’espace de détente à destination des plus jeunes situé à l’angle des bâtiments A et B ?",
        imageUrl: "C1-q1-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des jeux pour enfants.",
            value: "rep1",
            image: "C1-q1-1.svg",
          },
          {
            label: "Un terrain de pétanque.",
            value: "rep2",
            image: "C1-q1-2.svg",
          },
          {
            label: "Un jardin.",
            value: "rep3",
            image: "C1-q1-3.svg",
          },
          {
            label: "Des tables d’échec.",
            value: "rep4",
            image: "C1-q1-4.svg",
          },
          {
            label: "Un espace pour la biodiversité.",
            value: "rep5",
            image: "C1-q1-5.svg",
          },
          {
            label: "Un espace de détente.",
            value: "rep6",
            image: "C1-q1-6.svg",
          },
          {
            label: "Un espace d’exposition et de rencontree.",
            value: "rep7",
            image: "C1-q1-7.svg",
          },
          {
            label: "Un espace pour pratiquer le sport.",
            value: "rep8",
            image: "C1-q1-8.svg",
          },
          {
            label: "Un espace pique-nique.",
            value: "rep9",
            image: "C1-q1-9.svg",
          },
          {
            label: "De la pelouse libre.",
            value: "rep10",
            image: "C1-q1-10.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "C1-q1-11.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Création d’aires de détente et de partage",
        question:
          "Quel type de jeux ou espace de détente souhaitez-vous voir dans l’espace de détente à destination des séniors situé au sud du bâtiment G ?",
        imageUrl: "C1-q2-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un espace pour les jeunes.",
            value: "rep1",
            image: "C1-q2-1.svg",
          },
          {
            label: "Un espace au calme pour les séniors.",
            value: "rep2",
            image: "C1-q2-2.svg",
          },
          {
            label: "Un mix de ces deux espaces.",
            value: "rep3",
            image: "C1-q2-3.svg",
          },
          {
            label: "Un atelier de fabrication et de partage inter âge.",
            value: "rep4",
            image: "C1-q2-4.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "c2",
    type: "newLayout",
    titleStrong: "La fermeture du",
    titleWeak: "porche entre les bâtiments C et D",
    description:
      "Afin d’apporter une meilleure sécurité au sein de la résidence, il est envisagé de fermer le proche entre C et D, permettant de scinder en deux îlots le groupe BOUVET. L’accès piéton y sera maintenu mais l’entrée et la sortie pour l’accès au parking au cœur de l’ilôt EFGH se fera par rue Monthyon. Etes-vous favorable à la fermeture de ce porche. Pour vous exprimer cliquez sur personnaliser*. (*La décision sera prise en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C2-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "La fermeture du porche entre les bâtiments C et D",
        question:
          "Après avoir pris connaissance du projet de fermeture du porche entre les bâtiments C et D, êtes-vous favorable avec celui-ci ?",
        imageUrl: "C2-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, je suis favorable à la fermeture du porche.",
            value: "rep1",
            image: "C2-q1-1.svg",
          },
          {
            label: "Non, je ne suis pas favorable à la fermeture du porche.",
            value: "rep2",
            image: "C2-q1-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "c3",
    type: "newLayout",
    titleStrong: "Création de jardins",
    titleWeak: "partagés derrière les bâtiments A et B",
    description:
      "Le projet prévoit la création de jardins partagés à l’arrière des bâtiments A et B. Ces derniers seront équipés d’un local de stockage dédié pour les outils, engrais, terreau...ainsi que d’un robinet d’eau. Etes-vous intéressé? Quel type de culture souhaiteriez-vous? Aidez-nous à comprendre vos attentes. Pour cela, cliquez sur personnaliser. (*L’aménagement de ces espaces se fera en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C3-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Création de jardins partagés derrière les bâtiments A et B",
        question:
          "Seriez-vous prêt à vous impliquer au côté d’une association dans la création ou l’entretien d’un jardin partagé ?",
        imageUrl: "C3-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C3-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C3-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Création de jardins partagés derrière les bâtiments A et B",
        question:
          "Quel type de jardin partagé convient le plus à vos attentes ?",
        imageUrl: "C3-q2-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Des jardins individuels.",
            value: "rep1",
            image: "C3-q2-1.svg",
          },
          {
            label: "Des jardins collectifs.",
            value: "rep2",
            image: "C3-q2-2.svg",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Création de jardins partagés derrière les bâtiments A et B",
        question:
          "Quelle serait, selon vous, la forme de culture la plus sympa ?",
        imageUrl: "C3-q3-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Culture au sol.",
            value: "rep1",
            image: "C3-q3-1.svg",
          },
          {
            label: "Carré potager.",
            value: "rep2",
            image: "C3-q3-2.svg",
          },
          {
            label: "Bac surélevé.",
            value: "rep3",
            image: "C3-q3-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "c4",
    type: "newLayout",
    titleStrong: "La création",
    titleWeak: "d’une fresque",
    description:
      "La SHLMR envisage de réaliser une fresque sur l’un des murs de la résidence. Etes-vous intéressé par ce projet et seriez-vous prêt à vous impliquer ? Pour donner votre avis, cliquez sur personnaliser*. (*La création de cette fresque se fera en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C4-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "La création d’une fresque",
        question:
          "Etes-vous favorable à la réalisation d’une fresque artistique sur l’un des murs de la résidence ?",
        imageUrl: "C4-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C4-q1-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C4-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "La création d’une fresque",
        question:
          "Seriez-vous prêt à vous impliquer dans la création de cette fresque ?",
        imageUrl: "C4-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui.",
            value: "rep1",
            image: "C4-q2-1.svg",
          },
          {
            label: "Non.",
            value: "rep2",
            image: "C4-q2-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "c5-ac",
    type: "newLayout",
    titleStrong: "L’usage des locaux",
    titleWeak: "annexes au sein de votre résidence",
    description:
      "Dans votre bâtiment, deux locaux en rez-de-chaussée sont aujourd’hui inoccupés. Avez-vous des idées pour les utiliser ? Si vous souhaitez vous exprimer, cliquez sur personnaliser. (*Les aménagements de ces locaux se feront en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C5-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "L’usage des locaux annexes au sein de votre résidence",
        question:
          "Quel type d’aménagement souhaitez-vous dans ces locaux aujourd’hui inoccupés ? (Deux choix possibles)",
        imageUrl: "C5-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un espace de rencontre avec chaise et table.",
            value: "rep1",
            image: "C5-q1-1.svg",
          },
          {
            label: "Un local de rangement pour les besoins du jardin partagé.",
            value: "rep2",
            image: "C5-q1-2.svg",
          },
          {
            label: "Un local vélo.",
            value: "rep3",
            image: "C5-q1-3.svg",
          },
          {
            label: "Un local poussette.",
            value: "rep4",
            image: "C5-q1-4.svg",
          },
          {
            label: "Une salle de sporton.",
            value: "rep5",
            image: "C5-q1-5.svg",
          },
          {
            label: "Un local moto.",
            value: "rep6",
            image: "C5-q1-6.svg",
          },
          {
            label: "Un espace de jeu pour les enfants.",
            value: "rep7",
            image: "C5-q1-7.svg",
          },
          {
            label: "Un espace polyvalent.",
            value: "rep8",
            image: "C5-q1-8.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "C5-q1-9.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "c"],
  },
  {
    id: "c5-bdeh",
    type: "newLayout",
    titleStrong: "L’usage des locaux",
    titleWeak: "annexes au sein de votre résidence",
    description:
      "Dans votre bâtiment, un local en rez-de-chaussée sont aujourd’hui inoccupés. Avez-vous des idées pour les utiliser ? Si vous souhaitez vous exprimer, cliquez sur personnaliser. (*Les aménagements de ces locaux se feront en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C5-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "L’usage des locaux annexes au sein de votre résidence",
        question:
          "Quel type d’aménagement souhaitez-vous dans ces locaux aujourd’hui inoccupés ?",
        imageUrl: "C5-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un espace de rencontre avec chaise et table.",
            value: "rep1",
            image: "C5-q1-1.svg",
          },
          {
            label: "Un local de rangement pour les besoins du jardin partagé.",
            value: "rep2",
            image: "C5-q1-2.svg",
          },
          {
            label: "Un local vélo.",
            value: "rep3",
            image: "C5-q1-3.svg",
          },
          {
            label: "Un local poussette.",
            value: "rep4",
            image: "C5-q1-4.svg",
          },
          {
            label: "Une salle de sporton.",
            value: "rep5",
            image: "C5-q1-5.svg",
          },
          {
            label: "Un local moto.",
            value: "rep6",
            image: "C5-q1-6.svg",
          },
          {
            label: "Un espace de jeu pour les enfants.",
            value: "rep7",
            image: "C5-q1-7.svg",
          },
          {
            label: "Un espace polyvalent.",
            value: "rep8",
            image: "C5-q1-8.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "C5-q1-9.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["b", "d", "e", "h"],
  },
  {
    id: "c6",
    type: "newLayout",
    titleStrong: "Personnaliser",
    titleWeak: "votre logement",
    description:
      "Le programme de réhabilitation de la résidence s’accompagne également de la rénovation de votre logement. Pour cela, nous avons besoin de connaître vos envies et vos nécessités concernant votre future salle de bain et votre future cuisine. Pour vous exprimer sur ces sujets, cliquez sur personnaliser. (*Les aménagements se feront en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C6-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux prévoient de remplacer les baignoires de tous les logements par des douches. Dans cet objectif, nous aimerions savoir si vous ou l’un de vos proches souffrez d’un handicap ou de difficultés de déplacement et dans ce cas, si vous avez besoin d’aménagement particulier de type kit sénior (barre de maintien, WC réhaussé, siège de douche...) dans votre salle de bain",
        imageUrl: "C6-q1-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, j’ai besoin d’un kit sénior.",
            value: "rep1",
            image: "C6-q1-1.svg",
          },
          {
            label: "Non, je n’ai pas besoin de kit sénior.",
            value: "rep2",
            image: "C6-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-1-b",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux de la salle de bain prévoient le remplacement du carrelage au sol ainsi que la faïence. Ici vous trouverez une série de questions pour personnaliser votre salle de bain. En premier lieu, quel coloris de sol carrelé souhaitez-vous pour votre salle de bain ?",
        imageUrl: "C6-q1B-INTRO.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Un carrelage couleur graphite.",
            value: "rep1",
            image: "C6-q1B-1.png",
          },
          {
            label: "Un carrelage couleur sable.",
            value: "rep2",
            image: "C6-q1B-2.png",
          },
          {
            label: "Un carrelage couleur opale.",
            value: "rep3",
            image: "C6-q1B-3.png",
          },
        ],
      },
      {
        contentId: "content-1-c",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Enfin, quel coloris de carrelage souhaitez-vous pour la faïence de votre salle de bain ?",
        imageUrl: "C6-q1C-INTRO.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Une faïence couleur sable.",
            value: "rep1",
            image: "C6-q1C-1.png",
          },
          {
            label: "Une faïence couleur titane.",
            value: "rep2",
            image: "C6-q1C-2.png",
          },
          {
            label: "Une faïence couleur ciment.",
            value: "rep3",
            image: "C6-q1C-3.png",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux sont aussi l’occasion pour votre cuisine de refaire peau neuve. Ici vous trouverez une série de questions pour personnaliser votre cuisine. En premier lieu, quel coloris de sol carrelé souhaitez-vous pour votre cuisine ?",
        imageUrl: "C6-q2-INTRO.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Un carrelage couleur graphite.",
            value: "rep1",
            image: "C6-q2-1.png",
          },
          {
            label: "Un carrelage couleur sable.",
            value: "rep2",
            image: "C6-q2-2.png",
          },
          {
            label: "Un carrelage couleur opale.",
            value: "rep3",
            image: "C6-q2-3.png",
          },
        ],
      },
      {
        contentId: "content-3",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Ensuite, quel coloris souhaitez-vous pour le plan de travail de votre cuisine ?",
        imageUrl: "C6-q3-INTRO.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Un plan en stratifié couleur acier oxydé.",
            value: "rep1",
            image: "C6-q3-1.png",
          },
          {
            label: "Un plan en stratifié couleur aracena.",
            value: "rep2",
            image: "C6-q3-2.png",
          },
          {
            label: "Un plan en stratifié couleur bois.",
            value: "rep3",
            image: "C6-q3-3.png",
          },
        ],
      },
      {
        contentId: "content-4",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Enfin, quel coloris souhaitez-vous pour la crédence de votre cuisine ?",
        imageUrl: "C6-q4-INTRO.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Une crédence en inox brossé.",
            value: "rep1",
            image: "C6-q4-1.png",
          },
          {
            label: "Une crédence en mélaminé gris tourterelle.",
            value: "rep2",
            image: "C6-q4-2.png",
          },
          {
            label: "Une crédence en mélaminé bois.",
            value: "rep3",
            image: "C6-q4-3.png",
          },
        ],
      },
      {
        contentId: "content-5",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Les travaux vont aussi revoir tous les sols de vos salon et chambre. Les nouveaux sols mis en place dans le salon et le ou les chambres seront des sols souples. Quel coloris de sol souple a votre préférence parmi les propositions suivantes ?",
        imageUrl: "C6-q5-INTRO.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Un sol souple imitation béton.",
            value: "rep1",
            image: "C6-q5-1.png",
          },
          {
            label: "Un sol souple imitation parquet bois naturel.",
            value: "rep2",
            image: "C6-q5-2.png",
          },
          {
            label: "Un sol souple imitation parquet bois clair.",
            value: "rep3",
            image: "C6-q5-3.png",
          },
        ],
      },
      {
        contentId: "content-6",
        type: "radio",
        title: "Personnaliser votre logement",
        question:
          "Enfin, la dernière question de ce chapitre des travaux consacrés au logement, concerne le traitement des sols de tous les espaces extérieurs de votre logement: Balcons, varangue, loggia. (Selon votre cas) Quel couleur de sol carrelé souhaitez-vos sur vos espaces extérieurs ?",
        imageUrl: "C6-q6-INTRO.png",
        value: "",
        comment: "",
        options: [
          {
            label: "Un carrelage blanc.",
            value: "rep1",
            image: "C6-q6-1.png",
          },
          {
            label: "Un carrelage gris.",
            value: "rep2",
            image: "C6-q6-2.png",
          },
          {
            label: "Un carrelage perle.",
            value: "rep3",
            image: "C6-q6-3.png",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c", "d", "e", "f", "g", "h"],
  },
  {
    id: "c7-abcd",
    type: "newLayout",
    titleStrong: "La suppression des",
    titleWeak: "portes salon/entrée et entrée/cuisine",
    description:
      "Pour améliorer le confort thermique et la ventilation naturelle de votre logement, les travaux prévoient la suppression de la porte entre votre salon et votre entrée et la suppression de la porte entre votre cuisine et votre entrée. Etes-vous favorable à cette idée? Exprimez en cliquant sur personnaliser. (*L’ aménagement se fera en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C7-INTRO-ABCD.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "La suppression des portes salon/entrée et entrée/cuisine",
        question:
          "Parmi ces propositions pour la suppression des portes salon/entrée et entrée/cuisine afin d’améliorer la ventilation naturelle et votre confort thermique, laquelle vous semble la meilleure ?",
        imageUrl: "C7-INTRO-ABCD.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Supprimer la porte du salon et la porte de la cuisine.",
            value: "rep1",
            image: "C7-q1-1-ABCD.svg",
          },
          {
            label: "Supprimer uniquement la porte de la cuisine.",
            value: "rep2",
            image: "C7-q1-2-ABCD.svg",
          },
          {
            label: "Supprimer uniquement la porte du salon.",
            value: "rep3",
            image: "C7-q1-3-ABCD.svg",
          },
          {
            label: "Ne supprimer aucune porte.",
            value: "rep4",
            image: "C7-q1-4-ABCD.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b", "c", "d"],
  },
  {
    id: "c7-efgh",
    type: "newLayout",
    titleStrong: "La suppression de",
    titleWeak: "la porte de la cuisine",
    description:
      "Pour améliorer le confort thermique et la ventilation naturelle de votre logement, les travaux prévoient la suppression de la porte de votre cuisine. Etes-vous favorable à cette idée? Exprimez en cliquant sur personnaliser. (*L’ aménagement se fera en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.)",
    thumbnail: "C7-INTRO-EFGH.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "La suppression de la porte de la cuisine",
        question:
          "Seriez-vous d’accord pour supprimer la porte de votre cuisine afin d’améliorer la ventilation naturelle et votre confort thermique ?",
        imageUrl: "C7-INTRO-EFGH.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, je suis favorable à la suppression de la porte.",
            value: "rep1",
            image: "C7-q1-1-EFGH.svg",
          },
          {
            label: "Non, je ne suis pas favorable à cela.",
            value: "rep2",
            image: "C7-q1-2-EFGH.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["e", "f", "g", "h"],
  },
  {
    id: "c8-ab",
    type: "newLayout",
    titleStrong: "L’extension",
    titleWeak: "des loggias",
    description:
      "Sur les bâtiments A et B, les concepteurs du projet ont envisagé de créer une extension de votre loggia, afin de créer une varangue. Soit un nouvel espace extérieur d'environ 9m². *L’aménagement se fera en fonction des choix majoritairement exprimés par les résidents sur cette plateforme en ligne.",
    thumbnail: "C8-INTRO.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "L’extension des loggias",
        question:
          "Êtes-vous favorable au projet d’extension de votre loggia, sachant que l'ajout de cette extension entraînera une hausse de 4 m², et donc un loyer supplémentaire d'environ 30 euros/mois ?",
        imageUrl: "C8-INTRO.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Oui, je suis favorable au projet d’extension des loggias.",
            value: "rep1",
            image: "C8-q1-1.svg",
          },
          {
            label: "Non, je ne suis pas favorable à cela.",
            value: "rep2",
            image: "C8-q1-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: ["a", "b"],
  },
];
