import React, { useState } from "react";
import classes from "./ArticleModal.module.css";
// MUI
import { TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
// Components
import PDFReader from "../PdfViewer/PDFReader";

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ArticleModal = ({ show, setShow, type, content, screenWidth }) => {
  const classesMUI = useStyles();
  const articleContent =
    type === "pdf" ? (
      <PDFReader url={content} screenWidth={screenWidth} />
    ) : (
      <div className={classes.ArticleModal}>{content}</div>
    );
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classesMUI.modal}
        open={show}
        onClose={() => setShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={show}>
          <div className={classes.ModalContent}>{articleContent}</div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ArticleModal;
