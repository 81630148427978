import React, { useState } from "react";
import ReactPlayer from "react-player";

import classes from "./Projet.module.css";
import styled from "styled-components";

// Components
import Articles from "./Articles/Articles";
import UploadDocument from "./UploadDocument/UploadDocument";

const VideoWrapper = styled.div`
  margin: 1em auto;
  display: flex;
  justify-content: center;
`;

const Projet = ({ width, claims, clicked }) => {
  const [pdfs, setPdfs] = useState();
  return (
    <div className={classes.Projet}>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionSvg}>
          <img
            style={{ width: "100%" }}
            alt="hand"
            src={require(`../../../assets/homePage/concertation.svg`)}
          />
        </div>
        <div className={classes.BackgroundShape}></div>
        <div className={classes.Description}>
          <p>
            Construit entre 1973 et 1976, le groupe d’habitation de BOUVET,
            composé de huit bâtiments, va retrouver une nouvelle image.
          </p>
          <p>
            Deux axes de travaux sont prévus : la résidentialisation du groupe
            et la réhabilitation complète des 207 logements.
          </p>
          <div className={classes.VisiteVirtuelle}>
            <div
              style={{ marginBottom: "2rem" }}
              className={classes.ActionButtonOutside}
            >
              <p
                onClick={() =>
                  window.open(
                    "https://virtualtoureasy.com/tour/viewer/index.php?v=w4rcBSMEqbo3PZL0Wdpi"
                  )
                }
              >
                Visite virtuelle {">"}
              </p>
            </div>
            <ReactPlayer
              url="https://youtu.be/P6_hDQkyvZU"
              loop={true}
              width="100%"
              playing={false}
              controls={true}
            />
          </div>
          {/* <div className={classes.ActionButton}>
            <p onClick={clicked}>Accéder aux travaux {">"}</p>
          </div> */}
        </div>
      </div>
      <div className={classes.Articles}>
        <div className={classes.ArticlesHeader}>
          <h4 style={{ marginBottom: "1.5rem" }}>Actualités</h4>
          {claims && claims.admin && (
            <UploadDocument setPdfs={setPdfs} claims={claims} />
          )}
        </div>
        <Articles setPdfs={setPdfs} pdfs={pdfs} claims={claims} width={width} />
      </div>
    </div>
  );
};

export default Projet;
